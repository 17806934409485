<template>
  <div class="w-lg-100 bg-white p-10 p-lg-12 mx-auto auth-forms mb-20">
    <div class="bank-register">
      <img src="/media/misc/step-3.svg" class="bank-register__step" alt="step">

      <div class="bank-register__title">
        <router-link to="/bank-register/personal-info">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.22 6.7179C0.0793078 6.57738 0.000175052 6.38674 0 6.1879V5.8079C0.00230401 5.60949 0.0811163 5.41962 0.22 5.2779L5.36 0.147899C5.40648 0.101035 5.46178 0.0638379 5.52271 0.0384536C5.58364 0.0130692 5.64899 0 5.715 0C5.78101 0 5.84636 0.0130692 5.90729 0.0384536C5.96822 0.0638379 6.02352 0.101035 6.07 0.147899L6.78 0.857899C6.82656 0.903522 6.86356 0.957978 6.88881 1.01808C6.91406 1.07818 6.92707 1.14271 6.92707 1.2079C6.92707 1.27309 6.91406 1.33762 6.88881 1.39772C6.86356 1.45782 6.82656 1.51228 6.78 1.5579L2.33 5.9979L6.78 10.4379C6.82686 10.4844 6.86406 10.5397 6.88945 10.6006C6.91483 10.6615 6.9279 10.7269 6.9279 10.7929C6.9279 10.8589 6.91483 10.9243 6.88945 10.9852C6.86406 11.0461 6.82686 11.1014 6.78 11.1479L6.07 11.8479C6.02352 11.8948 5.96822 11.932 5.90729 11.9573C5.84636 11.9827 5.78101 11.9958 5.715 11.9958C5.64899 11.9958 5.58364 11.9827 5.52271 11.9573C5.46178 11.932 5.40648 11.8948 5.36 11.8479L0.22 6.7179Z" fill="black" />
          </svg>
        </router-link>
        {{ $t('Bank Details') }}
      </div>

      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        status-icon
        class="bank-register__form"
      >
        <el-form-item prop="product_area" class="hide-placeholder mb-15">
          <label for="">{{ $t('Follow Product Areas') }}</label>
          <el-select
            v-model="formData.product_area"
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in productAreas"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="countries" class="hide-placeholder">
          <label for="">{{ $t('Country of Residence') }}</label>
          <el-select
            v-model="formData.countries"
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in countries"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="w-100 d-flex justify-content-center mt-15">
        <button class="btn main-btn px-20 py-4 mt-7">
          {{ $t('Get Started') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BankSetup",
  data() {
    return {
      buttonIndicator: 'off',
      formData: {
        country: [],
        product_area: []
      },
      ruleForm: {
        country: [
          { required: true, message: this.$t("Please select Country of Residence"), trigger: "change" }
        ],
        product_area: [
          { required: true, message: this.$t("Please select Product Area"), trigger: "change" }
        ]
      }
    }
  },
  computed: {
    countries() {
      return ['Australia', 'India']
    },
    productAreas() {
      return ['Asset Mgmt', 'Trade Finance', 'Leasing']
    }
  }
}
</script>

<style scoped>

</style>
